/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@3.4.1/dist/js/bootstrap.min.js
 * - /npm/vanilla-lazyload@17.8.5/dist/lazyload.min.js
 * - /npm/swiper@11.0.3/swiper-bundle.min.js
 * - /npm/iconify-icon@1.0.8/dist/iconify-icon.min.js
 * - /gh/sfkios/sfw38s_js@3.0.0/dist/notify.min.js
 * - /gh/sfkios/sfw38s_js@3.0.0/dist/main.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
